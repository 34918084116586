import { EVENTS } from "@/common/events";
import { ERROR_RESPONSE } from "@/constants/ErrorResponse";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import { axiosCMSnoAuth } from "@/services/fetchers";
import {
  AuthSuccess,
  ChangePasswordInput,
  LoginInput,
  ProfileInput,
  RegisterInput,
} from "@/types/Auth";
import { RelatedMedia } from "@/types/Media";
import { StrapiResponse } from "@/types/Strapi/StrapiResponses";
import { User } from "@/types/User";
import { getProfileUrlErrorMessage } from "@/utils/responseUtils";
import {
  UseMutationResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useRouter } from "next/router";
import { posthog } from "posthog-js";
import { createContext, useContext, useState } from "react";
import { toast } from "react-hot-toast";
interface AuthContextData {
  user: User | null;
  login: (input: LoginInput) => void;
  register: (input: RegisterInput) => void;
  logout: (redirect?: string) => void;
  changePassword: (input: ChangePasswordInput) => void;
  updateProfile: (
    input: Partial<ProfileInput>,
    profilePhoto?: RelatedMedia
  ) => Promise<void>;
  resetPassword: (password: string) => void;
  loginMutation: UseMutationResult<AuthSuccess, AxiosError, LoginInput>;
  registerMutation: UseMutationResult<AuthSuccess, AxiosError, RegisterInput>;
  changePasswordMutation: UseMutationResult<
    AuthSuccess,
    AxiosError,
    ChangePasswordInput
  >;
  isLoading: boolean;
  isAuthenticated: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const JWT_KEY = process.env.NEXT_PUBLIC_JWT_KEY as string;

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isClient = typeof window !== "undefined";
  const hasJWT = isClient && Boolean(localStorage.getItem(JWT_KEY));
  const router = useRouter();
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(hasJWT);
  const [isLoading, setIsLoading] = useState(hasJWT);

  const phCaptureLogin = (user: User) => {
    posthog.capture(EVENTS.USER_LOGGED_IN, {
      user_id: user.id,
      email: user.email,
    });
  };

  const phIdentify = (user: User) => {
    posthog.identify(
      user.email,
      {
        first_name: user.firstName,
        last_name: user.lastName,
      },
      {
        user_id: user.id,
        email: user.email,
        created_at: user.createdAt,
      }
    );
  };

  const loginMutation = useMutation<AuthSuccess, AxiosError<any>, LoginInput>(
    AuthService.login,
    {
      onSuccess: (data) => {
        handleSuccess(data.jwt, data.user);
        const { user } = data;
        phCaptureLogin(user);
        phIdentify(user);
      },
      onError: (error: AxiosError<any>) => {
        if (
          error.response?.data?.error.message ===
          ERROR_RESPONSE.INVALID_CREDENTIALS.type
        ) {
          toast.error(ERROR_RESPONSE.INVALID_CREDENTIALS.message);
        } else {
          toast.error(ERROR_RESPONSE.DEFAULT_ERROR.message);
        }
      },
    }
  );

  const registerMutation = useMutation<
    AuthSuccess,
    AxiosError<any>,
    RegisterInput
  >(AuthService.register, {
    onSuccess: (data) => {
      const { jwt, user } = data;
      handleSuccess(jwt, user);
      posthog.capture(EVENTS.USER_REGISTERED, {
        user_id: user.id,
        email: user.email,
      });
      phIdentify(user);
    },
    onError: (error) => {
      if (
        error.response?.data?.error.message ===
        ERROR_RESPONSE.USER_ALREADY_EXISTS.type
      ) {
        toast.error(ERROR_RESPONSE.USER_ALREADY_EXISTS.message);
      } else if (
        getProfileUrlErrorMessage(error!.response!.data as StrapiResponse<any>)
      ) {
        toast.error("La URL de perfil ya existe");
      } else {
        toast.error(ERROR_RESPONSE.DEFAULT_ERROR.message);
      }
    },
  });

  const changePasswordMutation = useMutation<
    AuthSuccess,
    AxiosError<any>,
    ChangePasswordInput
  >(AuthService.changePassword, {
    onSuccess: () => toast.success("Contraseña cambiada"),
    onError: (error) => toast.error("Error cambiando contraseña"),
  });

  const handleSuccess = (jwt: string, user: User) => {
    localStorage.setItem(
      `${process.env.NEXT_PUBLIC_USER_TOKEN}`,
      JSON.stringify(user)
    );
    localStorage.setItem(JWT_KEY, jwt);

    setUser(user);
    phIdentify(user);

    if (router.query && router.query["redirect_url"]) {
      router.push(router.query["redirect_url"] as string);
    } else {
      router.replace("/");
    }
  };

  const login = (input: LoginInput) => loginMutation.mutate(input);

  const register = (input: RegisterInput) => registerMutation.mutate(input);

  const changePassword = (input: ChangePasswordInput) =>
    changePasswordMutation.mutate(input);

  const updateProfile = async (
    input: Partial<ProfileInput>,
    profilePhoto?: RelatedMedia
  ) => {
    try {
      const data = await AuthService.updateProfile(input as ProfileInput);
      setUser({
        ...user,
        ...data,
        profilePhoto: profilePhoto || user?.profilePhoto,
      });
      toast.success("Perfil actualizado");
    } catch (err: any) {
      if (err.response?.data?.error.message) {
        toast.error(err.response?.data?.error.message);
      } else {
        toast.error("Error al actualizar el perfil");
      }
    }
  };

  const logout = (redirect?: string) => {
    localStorage.removeItem(`${process.env.NEXT_PUBLIC_USER_TOKEN}`);
    localStorage.removeItem(JWT_KEY);
    if (posthog) {
      posthog.capture(EVENTS.USER_LOGGED_OUT, {
        user_id: user?.id,
        email: user?.email,
      });
      posthog.reset();
    }
    setUser(null);
    if (redirect) router.replace(redirect);
  };

  useQuery(["me"], async () => await UserService.me(), {
    enabled: isClient && Boolean(localStorage.getItem(JWT_KEY)),
    onSuccess: (data) => {
      localStorage.setItem(
        process.env.NEXT_PUBLIC_USER_TOKEN as string,
        JSON.stringify(user)
      );
      setUser(data);
      phIdentify(data);
      setIsLoading(false);
    },
    onError: (error) => {
      console.log(`error login in`, error);
      localStorage.removeItem(JWT_KEY);
      localStorage.removeItem(process.env.NEXT_PUBLIC_USER_TOKEN as string);
      setIsAuthenticated(false);
      setUser(null);
      setIsLoading(false);
      return error;
    },
  });

  const resetPassword = async (password: string) => {
    try {
      const code = router.query.code as string;
      if (!code) {
        toast.error("No hay un código de reseteo de contraseña");
        return;
      }
      const { data } = await axiosCMSnoAuth.post("/api/auth/reset-password", {
        code,
        password,
        passwordConfirmation: password,
      });
      toast.success("Contraseña cambiada exitosamente");
      router.push("/login");
    } catch (err: any) {
      toast.error(err.response.data.error.message);
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        register,
        changePassword,
        updateProfile,
        resetPassword,
        loginMutation,
        registerMutation,
        changePasswordMutation,
        isLoading,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
