import { Dialog, Transition } from "@headlessui/react";
import { Fragment, createContext, useContext, useState } from "react";

export interface ModalProps {
  title: string;
  subtitle: string;
  body: string | React.ReactNode;
  dismissable?: boolean;
}

interface ModalContextType {
  modalProps: ModalProps;
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType>({
  modalProps: {
    title: "",
    subtitle: "",
    body: "",
  },
  openModal: (props: ModalProps) => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({
    title: "",
    subtitle: "",
    body: "",
  });

  const openModal = (props: ModalProps) => {
    setModalProps(props);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        modalProps,
        openModal,
        closeModal,
      }}
    >
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 ">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="mb-4 text-base font-semibold leading-6 text-gray-900 lg:text-2xl"
                      >
                        {modalProps.title}
                      </Dialog.Title>
                      {modalProps.subtitle && (
                        <p className="text-sm text-gray-700">
                          {modalProps.subtitle}
                        </p>
                      )}
                      <div className="mt-2 text-left">{modalProps.body}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
