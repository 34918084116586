import "@/styles/globals.css";
import { api } from "@/utils/api";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "src/contexts/AuthContext";
import { ModalProvider } from "src/hooks/useModal";

if (process.env.NEXT_PUBLIC_API_MOCKING === "true") {
  require("../../msw/msw");
}

const DEV = process.env.NEXT_PUBLIC_ENV === "development";

const App = ({ Component, pageProps }: AppProps) => {
  const [queryClient] = useState(() => new QueryClient());

  const router = useRouter();

  const initPosthog = () => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "http://localhost:3000",
      // Disable in development
      loaded: (posthog) => {
        if (
          process.env.NEXT_PUBLIC_ENV === "development" &&
          process.env.NEXT_PUBLIC_POSTHOG_ACTIVE !== "true"
        ) {
          console.log("*****/n Posthog disabled in development /n*****");
          posthog.opt_out_capturing();
        }
      },
    });
  };
  const handleRouteChange = () => posthog.capture("$pageview");

  useEffect(() => {
    initPosthog();

    // Listen for route changes
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <AuthProvider>
              <ModalProvider>
                <div
                  aria-live="assertive"
                  className="pointer-events-none absolute inset-0 z-[100] mt-12 flex items-end px-4 py-6 sm:items-start sm:p-6"
                >
                  <Toaster />
                </div>
                {/* <BannerTop /> */}
                <Component {...pageProps} />
                <ReactQueryDevtools initialIsOpen={false} />
              </ModalProvider>
            </AuthProvider>
          </Hydrate>
        </QueryClientProvider>
      </PostHogProvider>
    </>
  );
};

export default api.withTRPC(App);
