import { ChangePasswordInput, ProfileInput, RegisterInput } from "@/types/Auth";
import { axiosCMSnoAuth } from "./fetchers";

const JWT_KEY = process.env.NEXT_PUBLIC_JWT_KEY as string;

const AuthService = {
  register: async ({ email, password, ...rest }: RegisterInput) => {
    const dto = {
      ...rest,
      email,
      username: email,
      password,
    };
    const { data } = await axiosCMSnoAuth.post("/api/auth/local/register", dto);
    return data;
  },

  login: async ({ email, password }: { email: string; password: string }) => {
    const dto = {
      identifier: email,
      password,
    };
    const { data } = await axiosCMSnoAuth.post("/api/auth/local", dto);
    return data;
  },

  forgotPassword: async (email: string) => {
    const dto = {
      email,
    };
    const { data } = await axiosCMSnoAuth.post(
      "/api/auth/forgot-password",
      dto
    );
    return data;
  },

  resendVerificationEmail: async (email: string) => {
    const dto = {
      email,
    };
    const { data } = await axiosCMSnoAuth.post(
      "/api/auth/send-email-confirmation",
      dto
    );
    return data;
  },

  updateProfile: async (profile: ProfileInput) => {
    const { data } = await axiosCMSnoAuth.put(`/api/users/me`, profile, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
      },
    });
    return data;
  },

  changePassword: async (dto: ChangePasswordInput) => {
    const { data } = await axiosCMSnoAuth.put(
      `/api/auth/change-password`,
      dto,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
        },
      }
    );
    return data;
  },
};

export default AuthService;
