import { StrapiResponse } from "@/types/Strapi/StrapiResponses";
import { AxiosError } from "axios";

export const handleAxiosError = (
  error: AxiosError<any> | null
):
  | {
      message: string;
      status: number;
      name: string;
    }
  | null
  | undefined => {
  if (!error) return null;
  const { response } = error;
  if (response) {
    return response.data.error;
  }
};

export function getProfileUrlErrorMessage(
  errorResponse: StrapiResponse<any>
): string | null {
  const { error } = errorResponse;
  if (
    error &&
    error.details &&
    error.details.errors &&
    error.details.errors.length > 0 &&
    error.details.errors[0].path.includes("profileUrl")
  ) {
    return error.details.errors[0].message;
  }
  return null;
}
