import axios, { ParamsSerializerOptions } from "axios";
import qs from "qs";

const axiosCMS = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS_URL,
  paramsSerializer: {
    serialize: (params: ParamsSerializerOptions | null) => {
      return qs.stringify(params, {
        encodeValuesOnly: true, // prettify URL
      });
    },
  },
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_TOKEN}`,
  },
});

const axiosCMSnoAuth = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS_URL,
  paramsSerializer: {
    serialize: (params: ParamsSerializerOptions | null) => {
      return qs.stringify(params, {
        encodeValuesOnly: true, // prettify URL
      });
    },
  },
});

export { axiosCMSnoAuth, axiosCMS };
