export const EVENTS = {
  // Security
  USER_LOGGED_IN: "user_logged_in",
  USER_LOGGED_OUT: "user_logged_out",
  USER_REGISTERED: "user_registered",

  // User
  USER_ADDED_LOCATION: "user_added_location",
  USER_ADDED_PROFILE_PHOTO: "user_added_profile_photo",

  // Courses
  LESSON_COMPLETED: "lesson_completed",
  LESSON_STARTED: "lesson_started",
  COURSE_COMPLETED: "course_completed",
  COURSE_STARTED: "course_started",
};
