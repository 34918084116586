import { ProfileInput } from "@/types/Auth";
import { axiosCMSnoAuth } from "./fetchers";

const JWT_KEY = process.env.NEXT_PUBLIC_JWT_KEY as string;

const UserService = {
  me: async () => {
    const token = localStorage.getItem(JWT_KEY);
    if (!token) throw new Error("No token found");
    const { data } = await axiosCMSnoAuth.get(`/api/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        populate: ["profilePhoto"],
      },
    });
    return data;
  },
  updateProfile: async (profile: ProfileInput) => {
    try {
      const { data } = await axiosCMSnoAuth.put(`/api/users/me`, profile, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(JWT_KEY)}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  },
};

export default UserService;
