export const ERROR_RESPONSE = {
  USER_ALREADY_EXISTS: {
    type: "Email or Username are already taken",
    message: "El usuario ya existe",
  },
  INVALID_CREDENTIALS: {
    type: "Invalid identifier or password",
    message: "Usuario o contraseña incorrectos",
  },
  NOT_CONFIRMED: {
    type: "Your account email is not confirmed",
    message: "Tu cuenta no esta verificada. Revisa tu correo.",
  },
  DEFAULT_ERROR: {
    message: "Ha ocurrido un error - Intenta nuevamente",
  },
};
